<template>
  <div class="mallListfrom">
    <div class="table-wrapper">
      <el-table
        :data="tableData"
        style="width: 100%; height: 100%"
        :height="tableHeight"
      >
        <el-table-column type="expand">
          <template slot-scope="props">
            <el-form label-position="left" inline class="demo-table-expand">
              <el-form-item label="商品名称:">
                <span>{{ props.row.name }}</span>
              </el-form-item>
              <el-form-item label="所属店铺:">
                <span>{{ props.row.shop }}</span>
              </el-form-item>
              <el-form-item label="商品 ID:">
                <span>{{ props.row.id }}</span>
              </el-form-item>
              <el-form-item label="店铺 ID:">
                <span>{{ props.row.shopId }}</span>
              </el-form-item>
              <el-form-item label="商品分类:">
                <span>{{ props.row.category }}</span>
              </el-form-item>
              <el-form-item label="店铺地址:">
                <span>{{ props.row.address }}</span>
              </el-form-item>
              <el-form-item label="商品描述:">
                <span>{{ props.row.desc }}</span>
              </el-form-item>
            </el-form>
          </template>
        </el-table-column>
        <el-table-column label="货架ID" prop="id" width="100">
        </el-table-column>
        <el-table-column label="商品名称" prop="name"> </el-table-column>
        <el-table-column label="描述" prop="desc"> </el-table-column>
        <el-table-column label="" width="50">
          <template slot-scope="scope">
            <el-button type="text" @click="handleClick(scope.row)"
              >编辑</el-button
            >
          </template>
        </el-table-column>
        <el-table-column label="操作" width="50">
          <template slot-scope="scope">
            <el-button type="text" @click="handleClick(scope.row)"
              >删除</el-button
            >
          </template>
        </el-table-column>
        <el-table-column label="" width="100">
          <template slot-scope="scope">
            <el-button type="text" @click="handleClick(scope.row)"
              >上架</el-button
            >
          </template>
        </el-table-column>
      </el-table>
    </div>
  </div>
</template>

<script>
export default {
  name: "MallList",
  data() {
    return {
      products: [
        {
          name: "产品1",
          unit: "个",
          dailyPrice: 100,
          retailPrice: 120,
          vpi1Price: 90,
          vpi2Price: 85,
          vpi3Price: 80,
          parameters: "参数1, 参数2",
          description: "这是产品1的介绍",
          manufacturerInfo: "厂家1的介绍",
          carouselImage: "path/to/carousel1.jpg",
          displayImage: "path/to/display1.jpg",
          showInMall: true,
        },
        // 注意：移除了最后一个对象后的逗号，并确保所有对象都有完整的属性
      ],
      tableData: [
        {
          id: "12987122",
          name: "好滋好味鸡蛋仔",
          category: "江浙小吃、小吃零食",
          desc: "荷兰优质淡奶，奶香浓而不腻",
          address: "上海市普陀区真北路",
          shop: "王小虎夫妻店",
          shopId: "10333",
        },
        {
          id: "12987123",
          name: "好滋好味鸡蛋仔",
          category: "江浙小吃、小吃零食",
          desc: "荷兰优质淡奶，奶香浓而不腻",
          address: "上海市普陀区真北路",
          shop: "王小虎夫妻店",
          shopId: "10333",
        },
      ],
      mallListFromRect: {},
    };
  },
  computed: {
    tableHeight() {
      return this.mallListFromRect ? this.mallListFromRect.height : "100%";
    },
  },
  activated() {
    console.log("Component activated (back to foreground)");
    // 可以在这里触发每次页面进入前台时的操作，比如重新加载数据
  },
  mounted() {
    this.mallListFromRect = this.$zcx.div_move({
      currentTarget: document.querySelector(".mallListfrom"),
    });
    // console.log(this.mallListFromRect.height);
  },
  methods: {},
};
</script>

<style>
@import "./mallList.less";
</style>
