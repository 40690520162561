<template>
  <div class="to">
    <div v-if="!Loadingvalue">
      <!-- <RouterView /> -->
      <RouterView @message-from-child="handleMessage"></RouterView>
    </div>
    <div v-if="Loadingvalue">
      <RouterView />
    </div>
  </div>
</template>

<script>
// import Loading from './pages/loading/loading.vue';

// import { RouterView } from 'vue-router';
// import ZcxAvatar from './components/ZcxAvatar.vue'

export default {
  name: "App",
  components: {
    // ZcxAvatar
  },
  computed: {
    // zcx_pathvalue:{
    //   if(!Loadingvalue){
    //      return this.zcx_path;
    //   };
    // }
  },
  created() {},
  data() {
    return {
      Loadingvalue: false,
      // zcx_path:'',
    };
  },
  methods: {
    handleMessage(message) {
      if (message && this.$zcx.zcx_usermr(1).eree === null) {
        this.Loadingvalue = true;
        location.hash = "#/index";
      }
    },
    ce() {
      location.hash = this.Loadingvalue === false ? "#/loading" : "#/index";
      this.Loadingvalue = this.Loadingvalue === true ? false : true;
    },
  },
};
</script>

<style lang="less" scoped>
@import "../src/styles/var.less";
</style>
