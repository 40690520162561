import axios from 'axios';

// 创建 Axios 实例
const axiosInstance = axios.create({
  baseURL: 'https://api.example.com/', // 这里可以设置默认的 API 基础 URL
  timeout: 10000, // 请求超时时间
  headers: {
    'Content-Type': 'application/json',
  },
});

// 请求拦截器
axiosInstance.interceptors.request.use(
  config => {
    // 在请求发送之前做些什么，例如添加 token
    // const token = store.state.auth.token; // 从 Vuex store 中获取 token
    // if (token) {
    //   config.headers.Authorization = `Bearer ${token}`;
    // }
    return config;
  },
  error => {
    // 对请求错误做些什么
    return Promise.reject(error);
  }
);

// 响应拦截器
axiosInstance.interceptors.response.use(
  response => {
    // 对响应数据做些什么
    return response.data;
  },
  error => {
    // 对响应错误做些什么
    // 你可以在这里处理网络错误、401/403 错误等
    console.error('API call error:', error);
    return Promise.reject(error);
  }
);

// 通用的请求方法
const request = (method, url, data = {}, params = {},headers = {}) => {
  return axiosInstance({
    method, 
    url,
    data,
    params,
    headers,
  });
};

// 导出请求函数
export const get = (url, params = {}, headers = {}) => request('get', url, {}, params, headers);
export const post = (url, data = {}, params = {}, headers = {}) => request('post', url, data, params, headers);
export const put = (url, data = {}, params = {}, headers = {}) => request('put', url, data, params, headers);
export const del = (url, data = {}, params = {}, headers = {}) => request('delete', url, data, params, headers);


export default axiosInstance;