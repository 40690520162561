<template>
    <div>
        <p>主页</p>
    </div>
 
</template>

<script>
export default {
    name:'zy'
}
</script>

<style>

</style>