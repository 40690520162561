<template>
  <div class="cwxto">
    <div class="totext">
      <h1>添加客服</h1>
      <div class="toimagetext">
        <p>长按识别，添加客服</p>
      </div>
      <div class="toimage">
        <img src="../../assets/wechatimage/zcxcewx.jpg" alt="二维码" />
      </div>
    </div>

    <!-- <button @click="startScan">开始扫描二维码</button> -->
  </div>
</template>
<script>
import wx from "weixin-js-sdk";
// import ewm from '@/assets/wechatimage/'
export default {
  data() {
    return {
      wxConfig: {
        appId: "", // 你的公众号的appid
        timestamp: "", // 生成签名的时间戳
        nonceStr: "", // 生成签名的随机串
        signature: "", // 签名
        jsApiList: ["scanQRCode"], // 需要调用的JS接口列表
      },
    };
  },
  mounted() {
    // this.initWeChat();
    // console.log(wx);
  },
  methods: {
    async initWeChat() {
      try {
        // 从服务器获取微信配置
        // const currentUrl = window.location.href;
        const configs = await this.$post(
          "https://env-00jxgwvpsgbo.dev-hz.cloudbasefunction.cn/wxt",
          { url: window.location.href }
        );
        // console.log(configs);
        // const configs = await this.$post(
        //   "https://env-00jxgwvpsgbo.dev-hz.cloudbasefunction.cn/wxt"
        // );
        console.log(window.location.href, configs);
        // configs = {};
        // 确保 configs 是有效的

        // if (!configs || !configs.appId || !configs.signature) {
        //   console.error("Invalid WeChat configuration data", configs);
        //   return;
        // }

        // this.wxConfig = configs;
        // console.log(this.wxConfig);
        // 确保 wx 对象已加载
        if (!wx) {
          console.error("wx 对象未加载");
          return;
        }

        wx.config({
          // debug: true,
          appId: configs.appId,
          timestamp: configs.timestamp,
          nonceStr: configs.nonceStr,
          signature: configs.signature,
          jsApiList: [
            // 需要使用的微信JS接口列表
            "scanQRCode",
            // ...
          ],
        });
        // console.log(wx);
        wx.error((err) => {
          console.error("微信JS-SDK配置错误", err);
        });
        wx.ready(() => {
          console.log("微信JS-SDK已准备好");
        });
      } catch (error) {
        console.error("初始化微信配置错误", error);
      }
    },
    startScan() {
      console.log(1111);
      wx.scanQRCode({
        needResult: 1, // 默认为0，扫描结果由微信处理，1则直接返回扫描结果
        scanType: ["qrCode", "barCode"], // 可以指定扫二维码还是一维码，默认二者都有
        success: (res) => {
          const result = res.resultStr; // 当needResult 为 1 时，扫码返回的结果
          console.log("扫描结果:", result);

          // 处理扫描结果，例如将结果展示给用户或者进一步处理
        },
      });
    },
  },
};
</script>

<style lang="less" scoped>
@import "./k.less";
</style>
