<template>
  <!-- 当visible为true时显示下拉菜单 -->
  <div v-if="visible">
    <!-- 遮罩层，点击时关闭下拉菜单 -->
    <div class="mask" @click="close"></div>

    <!-- 下拉菜单主体 -->
    <div class="drop-from" :style="dropdownStyle">
      <!-- 小三角形指示器 -->
      <div class="sjx"></div>
      <div class="drop-list">
        <!-- 搜索输入框 -->
        <input
          type="text"
          class="search-input"
          v-model="inputvalue"
          placeholder="搜索产品"
          @input="input_keydown"
        />
        <!-- 下拉列表选项 -->
        <div class="drop-listvalue" ref="dropListValue" @scroll="handleScroll">
          <p
            v-for="(item, index) in Screening"
            :key="index"
            @click="Drop_select(item)"
          >
            {{ item }}
          </p>
          <div v-if="isLoading" class="loading">加载中...</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ZcxDropdown",
  // props: {
  //   data: {
  //     type: Object,
  //     require: true,
  //   },
  //   top: {
  //     type: Number,
  //     require: true,
  //   },
  //   left: {
  //     type: Number,
  //     require: true,
  //   },
  //   width: {
  //     type: Number,
  //     default: 150,
  //   },
  // },
  data() {
    return {
      listvalue: [
        // 初始产品列表
        "产品11",
        "产品2",
        // ... 其他产品
      ],
      Screening: [], // 筛选后的列表
      inputvalue: "", // 搜索输入值
      visible: false, // 控制下拉菜单显示
      move: {}, // 存储位置信息
      componentName: "", // 组件名称
      type: "", // 下拉类型
      timeoutId: "", // 用于防抖的定时器ID
      loadedCount: 140,
      loadIncrement: 20,
      isLoading: false,
    };
  },
  computed: {
    // 获取用户信息
    mr() {
      return this.$zcx.zcx_usermr(1).ret;
    },
    // 计算下拉菜单的样式
    dropdownStyle() {
      const vhToPx = window.innerHeight * 0.005;
      const top = this.move.top + this.move.height + vhToPx;
      return {
        top: top + "px",
        left: this.move.left + "px",
        width: this.move.width + "px",
      };
    },
  },
  methods: {
    // 选择下拉项
    Drop_select(e) {
      this.$emit("close-event", [this.componentName, e]);
      this.close();
    },
    // 输入框键盘事件，使用防抖
    input_keydown() {
      clearTimeout(this.timeoutId);
      this.timeoutId = setTimeout(() => {
        this.inputselect();
      }, 300);
    },
    // 根据输入筛选列表
    inputselect() {
      if (this.inputvalue !== "") {
        this.Screening = this.$zcx.zcx_Match(this.listvalue, this.inputvalue);
      } else {
        this.resetScreening();
      }
    },
    // 重置筛选列表
    resetScreening() {
      this.loadedCount = 20;
      this.Screening = this.listvalue.slice(0, this.loadedCount);
    },
    // 关闭下拉菜单
    close() {
      this.visible = false;
    },
    // 打开下拉菜单
    async open(e) {
      // 显示加载提示
      const tiemMsg = this.$zcx.zcx_msgbox(
        this,
        true,
        { str: "加载中...", tiem: 20000 },
        {}
      );
      // 发送请求获取下拉列表数据
      const ret = await this.$post(
        "https://env-00jxgwvpsgbo.dev-hz.cloudbasefunction.cn/zcxcodeapi",
        {
          data: {
            type: "dropdown",
            rp: {
              listname: e.Droptype,
              name: null,
              ...this.reqdata()[0].data.rp,
            },
          },
        },
        {},
        {
          zcxy: "web",
          "Content-Type": "application/json",
        }
      );
      // 如果请求成更新列表值
      if (ret.data.eree === null) {
        this.listvalue = [...ret.data.ddlistvalue];
      }

      // 设置下拉菜单属性
      this.move = e.move;
      this.type = e.Droptype;
      this.componentName = e.componentName;
      this.visible = true;
      this.resetScreening();

      // 关闭加载提示
      this.$zcx.zcx_msgbox(this, false, {}, tiemMsg);
    },
    // 准备请求数据
    reqdata() {
      // 准备请求数据，包含用户信息
      const zcxdata = this.$zcxhttp.zcxhttpAdddata(
        "ce",
        {
          phone: this.mr.phone,
          adminid: this.mr.adminid,
          Authority: this.mr.Authority,
          token: this.mr.token,
        },
        {}
      );
      // 配置请求头和其他选项
      const config = {
        headers: {
          zcxy: "web",
          "Content-Type": "application/json",
          Authorization: "Bearer your_token_here",
        },
        timeout: 10000, // 10秒超时
        responseType: "json",
        withCredentials: false,
      };
      return [zcxdata, config];
    },
    handleScroll(event) {
      const element = event.target;
      if (
        !this.isLoading &&
        element.scrollHeight - element.scrollTop <= element.clientHeight + 50
      ) {
        this.loadMore();
      }
    },
    loadMore() {
      if (this.loadedCount < this.listvalue.length) {
        this.isLoading = true;
        setTimeout(() => {
          const newItems = this.listvalue.slice(
            this.loadedCount,
            this.loadedCount + this.loadIncrement
          );
          this.Screening.push(...newItems);
          this.loadedCount += this.loadIncrement;
          this.isLoading = false;
        }, 300);
      }
    },
  },
};
</script>

<style>
.mask {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  /* 完全透明 */
  /* background-color: rgba(255, 255, 255, 0);  */
  background-color: rgba(0, 0, 0, 0.4);
  z-index: 999; /* 确保遮罩层在最前面 */
}
.drop-from {
  display: flex;
  flex-direction: column;
  position: absolute;
  min-width: 150px;
  min-height: 100px;
  max-height: 500px;
  background-color: rgb(255, 255, 255);
  border-radius: 0.2vw;
  z-index: 1000; /* 确保下拉菜单在遮罩层之上 */
}
.drop-list {
  display: flex;
  flex-direction: column;
  justify-content: center; /* 水平居中 */
  align-items: center; /* 垂直居中 */
  margin-top: 0.5vh;
  max-height: 30vh;
  /* background-color: aquamarine; */
}
.drop-listvalue {
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  width: 100%;
  max-height: 26vh;
  scroll-behavior: smooth;

  /* 自定义滚动条样式 */
  &::-webkit-scrollbar {
    width: 8px;
  }

  &::-webkit-scrollbar-track {
    background: linear-gradient(to bottom, #f0f0f0, #e0e0e0);
    border-radius: 4px;
  }

  &::-webkit-scrollbar-thumb {
    background: linear-gradient(to bottom, #4facfe, #00f2fe);
    border-radius: 4px;
    transition: background 0.3s;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: linear-gradient(to bottom, #3a8ae6, #00d4e4);
  }

  /* 为Firefox添加滚动条样式 */
  scrollbar-width: thin;
  scrollbar-color: #4facfe #f0f0f0;
}

.drop-listvalue p {
  margin-left: 0.5vw;
  margin-top: 0.8vh;
  font-size: 0.5vw;
  cursor: default;
}

.drop-listvalue p:hover {
  color: #409eff;
}
.sjx {
  width: 0;
  height: 0;
  border: 0 solid transparent;
  border-right: 0.5vh solid transparent;
  border-left: 0.5vh solid transparent;
  border-bottom: 0.5vh solid rgb(255, 255, 255);
  margin-left: 0.5vw;
  margin-top: -0.45vh;
  /* background-color: aqua; */
}
.loading {
  text-align: center;
  padding: 10px 0;
  font-size: 14px;
  color: #999;
}

.search-input {
  width: 85%;
  height: 1.5vh; /* 将高度减半 */
  border: 0.01vw solid #000000;
  padding-left: 0.2vw;
  font-size: 0.4vw; /* 缩小字体 */
  margin-bottom: 0.5vh;
}
</style>
