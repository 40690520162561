import Vue from 'vue'
import App from './App.vue'
import "./styles/qj.less"
import router from './router'
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css';
import zcx from './zcx/zcx.js'
import zcxhttp from './zcx/zcxhttp.js'
import axios from 'axios'
import * as axiosMethods from '../src/zcx/axios.js'
import ZcxDropdown from './components/ZcxDropdown.vue'


Vue.component('ZcxDropdown', ZcxDropdown);
// import uniCloud from '@dcloudio/uni-cloud'

// uniCloud.init({
//   provider: 'alipay', // 或者 'tencent'
//   spaceId: 'env-00jxgwvpsgbo',
//   clientSecret:'HI8PM1pj62O7gEHW',
//   accessKey:'5V7y6SIgchl4r3Eb'
// });

// Vue.prototype.$uniCloud = uniCloud;
Vue.prototype.$get=axiosMethods.get;
Vue.prototype.$post = axiosMethods.post; // 允许访问 post 方法
Vue.prototype.$put = axiosMethods.put; // 允许访问 put 方法
Vue.prototype.$delete = axiosMethods.del; // 允许访问 delete 方法

Vue.prototype.$axios = axios;
Vue.prototype.$zcx = zcx;
Vue.prototype.$zcxhttp = zcxhttp;
Vue.config.productionTip = false
Vue.use(ElementUI);


new Vue({
  router,
  render: h => h(App),
}).$mount('#app')
