let httpdata={
    type:'',
    headers:{
     token:'',
     adminid:'',
     phone:'',
     authority:'',
    },
    rp:{},
    jsonv:{}
}
export default {

    zcxhttpAdddata(type,rp,jsonv){
        let obj={
            data:{...httpdata},
            url:'https://env-00jxgwvpsgbo.dev-hz.cloudbasefunction.cn/zcxcodeapi'
        }
        obj.data={
            type:`${type}`,  // 这个需要调用云函数（zcx_dlliandongappcn）的关键字，
            headers:{
            token:'',
            adminid:'',
            phone:'',
            authority:'',
            },
            rp:{...rp},
            jsonv:{...jsonv}
        }
        return obj
    }

}