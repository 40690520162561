import Vue from 'vue';
import VueRouter from 'vue-router';
Vue.use(VueRouter);




import index from '@/pages/home/index.vue';
import loading from '@/pages/loading/loading.vue'; 
import cwxshare from '@/pages/h5/cwxshare.vue';
import mall from '@/pages/views/mall/mall.vue';
import zy from '@/pages/views/zy/zy.vue'
const router = new VueRouter({
  //配置
  routes:[
    {path:"/index",
      component:index,
      children:[
        { path: "zy", component: zy },
        { path: "mall", component: mall },
        
      ]
    },//app.vue 使用
    {path:"/",component:loading},//app.vue 使用
    {path:'/wxshare',component:cwxshare},
    { path: "*", redirect: "/" }
    // {path:"/index/mall",component:mall}//index.vue 使用
  ]
});





export default router;