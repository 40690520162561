
import mall from '@/pages/views/mall/mall.vue';
import eree from '@/pages/views/eree/eree.vue';
import zy from '@/pages/views/zy/zy.vue';
import mallList from '@/pages/views/mallList/mallList.vue';
import wechat from '@/pages/views/wechat/wechat.vue';

const indexs={
    mall:mall,
    eree:eree,
    zy:zy,
    mallList:mallList,
    wechat:wechat
}

export default indexs;