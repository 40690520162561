<template>
  <div class="wx-form">
    <div
      class="wx-right"
      v-if="!socket && !isSocketConnected"
      style="
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100%;
      "
    >
      <div style="display: flex; flex-direction: column; align-items: center">
        <img
          src="../../../assets/image/webimage/wechatm.png"
          alt=""
          style="width: 10vh; height: 10vh"
        />
        <input
          type="password"
          v-model="starBottoken"
          style="width: 20vh; height: 3vh; margin-top: 1vh"
          :style="{
            boxShadow: '0 2px 5px rgba(0, 0, 0, 0.4)',
          }"
        />

        <button
          style="
            width: 20vh;
            height: 3vh;
            /* background-color: #12b500; */

            font-size: 1.2vh;
            margin-top: 1vh;
          "
          :style="{
            background: 'linear-gradient(83deg, #a6f75d 0%, #00e3ae 100%)',
            boxShadow: '0 2px 5px rgba(0, 0, 0, 0.4)',
          }"
          @click="wechatserver"
        >
          连接
        </button>
      </div>
    </div>
    <div class="wx-right" v-if="socket && isSocketConnected">
      <div class="wx-header">
        <!-- 上方导航 -->
        <button
          v-for="(item, index) in wechanform_right_header"
          :key="index"
          :style="{
            backgroundColor: chatbuttonindex === index ? '#ffffff' : '',
            color: chatbuttonindex === index ? '#000' : '',
            boxShadow:
              chatbuttonindex === index ? '0 2px 5px rgba(0, 0, 0, 0.4)' : '',
          }"
          @click="_chatbuttonindex(index)"
        >
          {{ item }}
        </button>
      </div>

      <div class="wx-content">
        <!-- 聊天框 -->
        <div class="wx-list-content btrr0 bbrr0" style="z-index: 10">
          <div style="margin-top: 1vh; margin-bottom: 1vh">
            <p
              style="text-align: center; margin-bottom: 0.5vh"
              :style="{
                color: isSocketConnected ? 'green' : 'red',
              }"
            >
              {{ isSocketConnected ? "本地服务器已连接" : "本地服务器未连接" }}
            </p>
            <div
              style="
                display: flex;
                align-items: center;
                justify-content: center;
              "
            >
              <input
                type="text"
                v-model="connectUrl"
                style="width: 80%; height: 2vh; color: #98a0a8"
              />
              <p
                class="el-icon-refresh-right"
                style="font-size: 2vh"
                @click="wechatserver"
              ></p>
            </div>
          </div>
          <!-- 本地服务器区域 -->
          <div
            style="
              display: flex;
              flex-direction: row;
              justify-content: center;
              align-items: center;
              height: 5vh;
              font-size: 1.5vh;
            "
          >
            <!-- <h3>微信列表</h3> -->
            <p style="color: #12b500">微信列表</p>
            <p
              class="el-icon-refresh-right"
              style="font-size: 1.5vh"
              @click="wechatlistadd"
            ></p>
            <!-- <button style="width: 5vh; height: 2vh; margin-top: 0">获取</button> -->
          </div>

          <div class="wx-list">
            <div
              class="wx-item"
              v-for="(item, index) in wechatlist"
              :key="index"
              @click="_buttonindex(index)"
              :style="{
                backgroundColor: buttonindex === index ? '#e6f2ff' : '',
                boxShadow:
                  buttonindex === index ? '0 2px 5px rgba(0, 0, 0, 0.4)' : '',
              }"
            >
              <img
                :src="item.image"
                alt=""
                style="width: 3vh; height: 3vh; margin-right: 0.5vh"
              />
              {{ item.wxname }}
            </div>
          </div>
          <!-- 微信列表区域 -->
        </div>
        <div
          class="wx-sidebar"
          style="
            width: 3%;
            background-color: #e6f2ff;
            box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
            border-top-right-radius: 0;
            border-bottom-right-radius: 0;
            z-index: 8;
          "
        >
          <div style="width: 100%; height: 3vh"></div>
          <div
            class="wx-icon"
            :style="{
              backgroundColor: grqulistindex === 0 ? '#8fc6ff' : '',
              boxShadow:
                grqulistindex === 0 ? '0 2px 5px rgba(0, 0, 0, 0.4)' : '',
            }"
            @click="_grqulistindex(0)"
          >
            <img
              src="../../../assets/image/webimage/wxchat.png"
              alt=""
              style="width: 3vh; height: 3vh"
            />
          </div>
          <div
            class="wx-icon"
            :style="{
              backgroundColor: grqulistindex === 1 ? '#8fc6ff' : '',
              boxShadow:
                grqulistindex === 1 ? '0 2px 5px rgba(0, 0, 0, 0.4)' : '',
            }"
            @click="_grqulistindex(1)"
          >
            <img
              src="../../../assets/image/webimage/wxchat2.png"
              alt=""
              style="width: 3vh; height: 3vh"
            />
          </div>
        </div>

        <!-- 微信个人，群，区域 -->
        <div
          class="wx-content"
          style="
            display: flex;
            flex-direction: column;
            margin-top: 0;
            width: 10%;
            box-shadow: 0 2px 5px rgba(0, 0, 0, 0.5);
          "
        >
          <div
            style="
              width: 100%;
              height: 3vh;
              background-color: #e6f2ff;
              z-index: 9;
              display: flex;
              justify-content: center;
              align-items: center;
            "
          >
            <input
              type="text"
              name=""
              id=""
              style="width: 80%; height: 2vh; color: #909399"
              v-model="searchtext"
              @input="searchtextInput"
            />
            <transition name="fade">
              <p
                class="el-icon-search"
                style="margin-left: 0.5vh"
                v-if="searchtext === ''"
              ></p>
              <p
                class="el-icon-circle-close"
                style="margin-left: 0.5vh"
                v-if="searchtext !== ''"
                @click="searchtextdelete"
              ></p>
            </transition>
          </div>
          <!-- 搜索联系人-->

          <div
            v-if="grqulistindex === 0"
            class="wx-list-content btrr0 bbrr0"
            style="
              border-top-left-radius: 0;
              border-bottom-left-radius: 0;
              margin-left: 0;
              z-index: 8;
              width: 100%;
              box-shadow: none;
            "
            @scroll="handleScroll"
            ref="scrollableDiv"
          >
            <!-- 好友列表 -->
            <button
              v-for="(item, index) in sortedGruserlist"
              :key="index"
              @click="_chatobjdata(item, index)"
              style="position: relative"
            >
              <!-- src="../../../assets/image/webimage/wxchat.png" -->
              <img
                :src="item.avatarUrl"
                alt=""
                style="width: 3vh; height: 3vh; margin-right: 0.5vh"
              />
              <div style="margin-right: 1vh">
                {{ item.remark !== "" ? item.remark : item.nickname }}
              </div>
              <p
                style="
                  position: absolute;
                  top: 0;
                  right: 0;
                  background-color: red;
                  color: white;
                  border-radius: 50%;
                  padding: 0.1vh 0.3vh;
                  font-size: 0.8vh;
                "
                v-if="Object.keys(item.msgdata).length > 0"
              >
                {{
                  Object.keys(item.msgdata).filter(
                    (key) => !item.msgdata[key].isViewed
                  ).length
                }}
              </p>
              <!-- 悬浮的红色提示标签 -->
            </button>
          </div>

          <!-- 好友列表区域 -->

          <div
            v-if="grqulistindex === 1"
            class="wx-list-content btrr0 bbrr0"
            style="
              border-top-left-radius: 0;
              border-bottom-left-radius: 0;
              margin-left: 0;
              z-index: 8;
              width: 100%;
              box-shadow: none;
            "
            @scroll="handleScroll"
            ref="scrollableDiv"
          >
            <!-- 群列表 -->
            <button
              v-for="(item, index) in sortedQluserlist"
              :key="index"
              @click="_chatobjdata(item, index)"
              style="position: relative"
            >
              <img
                src="../../../assets/image/webimage/wxchat.png"
                alt=""
                style="width: 3vh; height: 3vh; margin-right: 0.5vh"
              />
              <div style="margin-right: 1vh">{{ item.groupName }}</div>
              <p
                style="
                  position: absolute;
                  top: 0;
                  right: 0;
                  background-color: red;
                  color: white;
                  border-radius: 50%;
                  padding: 0.1vh 0.3vh;
                  font-size: 0.8vh;
                "
                v-if="Object.keys(item.msgdata).length > 0"
              >
                {{
                  Object.keys(item.msgdata).filter(
                    (key) => !item.msgdata[key].isViewed
                  ).length
                }}
              </p>
            </button>
          </div>
        </div>

        <!-- 好友列表区域 -->
        <div
          class="wx-list-content"
          style="
            border-top-left-radius: 0;
            border-bottom-left-radius: 0;
            margin-left: 0;
            z-index: 7;
            background-color: #e6f2ff;
            width: 2vh;
            transition: all 0.3s ease;
          "
          :style="{
            width: !msgfrom ? '2vh' : '0%',
          }"
          @click="msgfrom = !msgfrom"
        >
          <p
            class="el-icon-caret-right"
            @click="msgfrom = !msgfrom"
            style="margin-top: 0.5vh"
          ></p>
        </div>
        <!-- 聊天缩小的窗口区域 -->
        <div
          class="wx-list-content"
          style="
            border-top-left-radius: 0;
            border-bottom-left-radius: 0;
            margin-left: 0;
            z-index: 6;
            background-color: #e6f2ff;
            width: 30%;
            transition: all 0.3s ease;
          "
          :style="{
            width: msgfrom ? '30%' : '0%',
          }"
        >
          <!-- 聊天放大的窗口内容 -->
          <div
            style="
              display: flex;
              align-items: center;
              width: 100%;
              height: 2vh;
              margin-top: 0.5vh;
              margin-bottom: 0.5vh;
            "
          >
            <p style="margin-left: 0.5vh; color: #12b500">
              {{
                grqulistindex === 0
                  ? chatobjdata.remark !== ""
                    ? chatobjdata.remark
                    : chatobjdata.nickname
                  : chatobjdata.groupName
              }}
            </p>
            <p style="margin-left: 0.5vh; color: #b8b8b8; font-size: 0.8vh">
              {{
                grqulistindex === 0
                  ? chatobjdata.wxId !== ""
                    ? chatobjdata.wxId
                    : ""
                  : chatobjdata.groupId
              }}
            </p>
            <p
              class="el-icon-circle-close"
              style="margin-left: auto; margin-right: 0.5vh"
              @click="msgfrom = !msgfrom"
            ></p>
          </div>
          <!-- 聊天放大的窗口内容-标题 -->
          <div style="width: 100%; height: 80%; background-color: #fff">
            测试消息
          </div>
          <!-- 聊天放大的窗口内容-聊天记录 -->
          <div
            style="
              width: 100%;
              height: 12%;
              background-color: #fff;
              /* border-top: 0.2vh solid #adb6bf; */
            "
          >
            <el-input
              type="textarea"
              :rows="4"
              placeholder="请输入内容"
              v-model="textarea"
              resize="none"
              style="width: 100%; font-size: 1.2vh"
              @keydown.enter.prevent.native="wechatsend"
            >
            </el-input>
          </div>
          <!-- 聊天放大的窗口内容-输入框 -->
          <div
            style="
              width: 100%;
              height: 5%;
              background-color: #fff;
              overflow: visible;
            "
          >
            <button
              style="
                width: 10vh;
                height: 3vh;
                color: #12b500;
                margin-left: auto;
                margin-right: 2vh;
                margin-top: -0.5vh;
                display: flex;
                align-items: center;
                justify-content: center;
                font-size: 1.2vh;
              "
              @click="wechatsend"
            >
              发送
            </button>
          </div>
          <!-- 聊天放大的窗口内容-发送按钮 -->
        </div>
        <!-- 聊天放大的窗口区域 -->
        <!-- <div class="wx-list-content">
          连接
          <input type="text" v-model="connectUrl" />
          <button style="height: 3vh; width: 10vh" @click="wechatserver">
            连接
          </button>
        </div> -->
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
// import { forEach } from "core-js/core/array";
export default {
  data() {
    return {
      wxId: [
        "wxid_q587uwvpu9fk22",
        "wxid_z4q9wt9lxfiu22",
        "wxid_vhjpna9pjsb241",
        "wxid_qael24f2jyli22",
      ], // 机器人微信ID
      wechatlist: [], // 微信列表数据
      buttonlist: ["添加群成员", "删除群成员", "群发消息"], // 聊天上方导航
      wechanform_right_header: [
        // "聊天",
        "群发消息",
        "聊天记录",
        "文案设置",
        "设置",
      ], // 聊天上方导航
      gruserlist: [
        // ["wxid", "张三", "url"],
        // ["wxid", "张三", "url"],
        // ["wxid", "张三", "url"],
      ], // 好友列表数据
      qluserlist: [], // 群列表数据
      buttonindex: 0, // 微信列表按钮index
      grqulistindex: 0, // 好友，群列表 定位index
      chatbuttonindex: 0, // 聊天按钮index
      tiemMsg: null, //弹窗消息ID
      connectUrl: "ws://127.0.0.1:10000", // 本地服务器ip
      socket: null, // 本地服务器
      socketStatus: WebSocket.CLOSED, // 本地服务器状态
      msgfrom: false, // 聊天窗口是否打开
      textarea: "", // 聊天输入框
      chatobjdata: "", // 聊天对象数据
      starBottoken:
        "05945ca04818064cc7e6b1adf2c157482f6818ee007fd0e6bad9eb505b9385e2-18797e065b1e34585742d6b1efd40acba",
      messagesList: [], // 消息列表
      searchtext: "", // 搜索文本
      timeoutId: null, // 搜索防抖
      SearchValue: [], // 搜索值
    };
  },
  computed: {
    tableHeight() {
      return this.mallListFromRect ? this.mallListFromRect.height : "100%";
    }, // 表格高度
    isSocketConnected() {
      return this.socket && this.socketStatus === WebSocket.OPEN;
    }, // 本地服务器是否连接
    sortedQluserlist() {
      return this.qluserlist.slice().sort((a, b) => {
        // 使用 slice() 创建副本
        const aHasUnread = Object.keys(a.msgdata).some(
          (key) => !a.msgdata[key].isViewed
        );
        const bHasUnread = Object.keys(b.msgdata).some(
          (key) => !b.msgdata[key].isViewed
        );
        return (bHasUnread ? 1 : 0) - (aHasUnread ? 1 : 0); // 有未读消息的排在前面
      });
    },
    sortedGruserlist() {
      return this.gruserlist.slice().sort((a, b) => {
        // 使用 slice() 创建副本
        const aHasUnread = Object.keys(a.msgdata).some(
          (key) => !a.msgdata[key].isViewed
        );
        const bHasUnread = Object.keys(b.msgdata).some(
          (key) => !b.msgdata[key].isViewed
        );
        return (bHasUnread ? 1 : 0) - (aHasUnread ? 1 : 0); // 有未读消息的排在前面
      });
    },
  },
  // async created() {
  //   this.tiemMsg = this.$zcx.zcx_msgbox(
  //     this,
  //     true,
  //     { str: "获取微信登录状态...", tiem: 500 },
  //     {}
  //   );
  //   const result = await this.wechatserver();
  //   if (result) {
  //     this.$message({
  //       type: result ? "success" : "warning",
  //       message: result ? `本地服务器成功` : "本地服务器连接失败",
  //     });
  //   }
  // },
  async activated() {
    //组件打开
  }, // 页面激活组件打开钩子函数
  deactivated() {
    console.log("组件已经关闭");
  },
  mounted() {
    this.mallListFromRect = this.$zcx.div_move({
      currentTarget: document.querySelector(".wx-form"),
    }); //拿到窗口大小
  },
  methods: {
    searchtextInput() {
      clearTimeout(this.timeoutId);
      this.timeoutId = setTimeout(() => {
        this.inputselect();
      }, 500);
    },
    inputselect() {
      if (this.searchtext !== "") {
        this.SearchValue = this.$zcx.zcx_MatchObject(
          this.grqulistindex === 0
            ? this.wechatlist[this.buttonindex].gruserlist
            : this.wechatlist[this.buttonindex].qluserlist,
          this.grqulistindex === 0 ? ["nickname", "remark"] : ["groupName"],
          this.searchtext
        );
        if (this.grqulistindex === 0) {
          this.gruserlist = Object.values(this.SearchValue).slice(0, 100);
        } else if (this.grqulistindex === 1) {
          this.qluserlist = Object.values(this.SearchValue).slice(0, 24);
        }

        // console.log(this.SearchValue, 111);
      } else {
        this.SearchValue = [];
        if (this.grqulistindex === 0) {
          this.gruserlist = Object.values(
            this.wechatlist[this.buttonindex].gruserlist
          ).slice(0, 100);
        } else if (this.grqulistindex === 1) {
          this.qluserlist = Object.values(
            this.wechatlist[this.buttonindex].qluserlist
          ).slice(0, 24);
        }
      }
    },
    searchtextdelete() {
      this.searchtext = "";
      this.SearchValue = [];
      if (this.grqulistindex === 0) {
        this.gruserlist = Object.values(
          this.wechatlist[this.buttonindex].gruserlist
        ).slice(0, 100);
      } else if (this.grqulistindex === 1) {
        this.qluserlist = Object.values(
          this.wechatlist[this.buttonindex].qluserlist
        ).slice(0, 24);
      }
    },
    handleScroll() {
      // console.log(div.scrollTop, div.clientHeight);
      // console.log(this.wechatlist[this.buttonindex], "cesf");
      const div = this.$refs.scrollableDiv; // 获取引用的 div

      if (div.scrollTop + div.clientHeight >= div.scrollHeight - 10) {
        // console.log("滑动到底部");
        const currentLength =
          this.grqulistindex === 0
            ? this.gruserlist.length
            : this.qluserlist.length; // 获取当前 gruserlist 的长度
        let newItems = "";
        if (this.grqulistindex === 0) {
          if (this.SearchValue.length > 0) {
            newItems = Object.values(this.SearchValue).slice(
              currentLength,
              currentLength + 50
            );
          } else {
            newItems = Object.values(
              this.wechatlist[this.buttonindex].gruserlist
            ).slice(currentLength, currentLength + 50);
          }

          this.gruserlist.push(...newItems);
          // console.log(this.gruserlist.length);
        } else if (this.grqulistindex === 1) {
          // console.log(Object.values(this.wechatlist[this.buttonindex]).length);
          if (this.SearchValue.length > 0) {
            newItems = Object.values(this.SearchValue).slice(
              currentLength,
              currentLength + 5
            );
          } else {
            newItems = Object.values(
              this.wechatlist[this.buttonindex].qluserlist
            ).slice(currentLength, currentLength + 5);
          }

          this.qluserlist.push(...newItems);
          // console.log(this.qluserlist.length);
        }

        // 从当前长度开始获取下一个 100 个值
        // this.gruserlist.push(...newItems); // 将新值推送到 gruserlist 中
        // console.log(this.gruserlist.length);
      }
    },
    async wechatlistadd() {
      if (this.isSocketConnected) {
        const date = {
          D: {
            type: "getAccountListRequest",
            params: {
              // wxId: wxId,
              // cache: 1,
              status: 1,
            },
          },
          H: {
            headers: {
              Authorization: this.starBottoken,
            },
          },
        };
        // console.log(date, 1111112);
        const ret = await this.wechatsendapi(date);
        // console.log(ret, 1111112);
        for (const item of ret.data.data) {
          if (
            this.wxId.includes(item.wxId) &&
            !this.wechatlist.some(
              (existingItem) => existingItem.wxId === item.wxId
            )
          ) {
            this.wechatlist.push({
              wxId: item.wxId,
              wxname: item.nickname,
              image: item.avatarUrl,
              gruserlist: {},
              qluserlist: {},
            });
          }
        }

        if (ret.data.data.length === 0) {
          this.$message({
            type: "warning",
            message: `未检测到可用微信账号`,
          });
        }
        if (this.wechatlist.length === 0 && ret.data.data.length > 0) {
          this.$message({
            type: "warning",
            message: `当前登录微信账号没有开通权限`,
          });
        }
        // for (const (item,index) of this.wechatlist) {
        //   console.log(item);

        // }
        for (const item of this.wechatlist) {
          const ret = await this._wechatuserListloading(item.wxId);
          item.gruserlist = ret[0];
          item.qluserlist = ret[1];
          // console.log(item.wxname, item);
        }
        this.gruserlist = Object.values(
          this.wechatlist[this.buttonindex].gruserlist
        ).slice(0, 100);
        this.qluserlist = Object.values(
          this.wechatlist[this.buttonindex].qluserlist
        ).slice(0, 24);
        console.log(this.gruserlist, this.qluserlist, "5161d65sa");
        // this.gruserlist = this.wechatlist[this.grqulistindex].gruserlist;
        // this.qluserlist = this.wechatlist[this.grqulistindex].qluserlist;
        // console.log(this.gruserlist, this.qluserlist, this.wechatlist);
        //  const ret =await this._wechatuserListloading(index)
      }
    },
    getConnectionCount() {
      // 根据您的实际情况实现获取连接数的逻辑
      // 例如：
      return this.connections?.length || 0; // 假设connections是存储连接的数组
    },
    async wechatserver() {
      this.tiemMsg = this.$zcx.zcx_msgbox(
        this,
        true,
        { str: "正在连接本地服务器...", tiem: 10000 },
        {}
      );
      return new Promise((resolve, reject) => {
        try {
          // 如果已经存在连接且处于开启状态，直接返回
          if (this.socket && this.socket.readyState === WebSocket.OPEN) {
            resolve("WebSocket连接已存在");
            return;
          }
          // this.socket.close();
          // // 创建新连接
          this.socket = new WebSocket(this.connectUrl);

          const timeout = setTimeout(() => {
            reject(new Error("WebSocket连接超时"));
          }, 5000); // 5秒超时
          // 连接成功
          this.socket.onopen = async () => {
            clearTimeout(timeout);
            this.socketStatus = WebSocket.OPEN;
            this.tiemMsg = this.$zcx.zcx_msgbox(
              this,
              true,
              { str: "验证token...", tiem: 10000 },
              {}
            );
            const ret23 = await this.wechatsendapi(
              {},
              {
                type: "verifyToken",
                adminid: "dl18190972079",
                token: this.starBottoken,
              }
            );
            // console.log(ret23, 1112313);
            if (!ret23.data.ret) {
              this.socket = null;
              this.$message({
                type: "error",
                message: `Token错误`,
              });
              this.$zcx.zcx_msgbox(this, false, {}, this.tiemMsg);
              resolve(false);
              return;
            }
            this.$zcx.zcx_msgbox(this, false, {}, this.tiemMsg);
            this.$message({
              type: "success",
              message: `本地服务器成功`,
            });
            await this.wechatlistadd();
            resolve(true);
          };

          // 接收消息
          this.socket.onmessage = (event) => {
            try {
              // const data = event.data;
              // console.log(data, 1111);
              // console.log("收到原始消息:", data);

              // // 尝试解析 JSON
              const message = JSON.parse(event.data);
              // console.log(message);

              // // 处理不同类型的消息;
              if (message.type.type !== "api") {
                // API 响应处理
                this.handleOtherMessage(message);
              }

              // else {
              //   // 其他类型消息处理
              //   this.handleOtherMessage(message);
              // }
            } catch (error) {
              console.log("收到非 JSON 消息:", event.data);
            }
          };

          // 错误处理

          try {
            this.socket.onerror = (error) => {
              clearTimeout(timeout);
              // console.error("WebSocket错误:", error);
              this.socketStatus = WebSocket.CLOSED;
              resolve(false, error);
              // reject(error);
            };

            this.socket.onclose = () => {
              clearTimeout(timeout);
              // console.log("WebSocket连接已关闭");

              this.socket = null;
              this.$zcx.zcx_msgbox(this, false, {}, this.tiemMsg);
              this.$message({
                type: "warning",
                message: "本地服务器连接失败",
              });
              this.socketStatus = WebSocket.CLOSED;
            };
          } catch (error) {
            resolve(false);
          }
        } catch (error) {
          reject(error);
        }
      });
    }, // 连接本地服务器
    async wechatsendapi(obj, type_a) {
      return new Promise((resolve, reject) => {
        const requestId = Date.now().toString();
        const msgdata = {
          type: {
            id: requestId,
            type: type_a !== undefined ? "apiA" : "api",
          },
          data: type_a === undefined ? obj : type_a,
        };
        // console.log(msgdata, 111111);

        // 创建一次性消息处理函数
        const messageHandler = (event) => {
          const response = JSON.parse(event.data);
          if (response.type.id === requestId) {
            // 收到对应请求的响应后，移除这个临时的事件监听器
            this.socket.removeEventListener("message", messageHandler);
            clearTimeout(timeout);
            resolve(response);
          }
        };

        // 添加临时消息处理器
        this.socket.addEventListener("message", messageHandler);

        // 发送消息
        this.socket.send(JSON.stringify(msgdata));

        // 设置超时
        const timeout = setTimeout(() => {
          this.socket.removeEventListener("message", messageHandler);
          reject(new Error("请求超时"));
        }, 5000);
      });
    },
    wechatsend() {
      if (this.textarea !== "") {
        this.socket.send(this.textarea);
        this.textarea = "";
      }

      // if (this.socket && this.socket.readyState === WebSocket.OPEN) {

      //   console.log("消息已发送:", this.wechatmsg);
      //   this.wechatmsg = ""; // 清空输入框
      // } else {
      //   console.error("WebSocket 连接未建立,无法发送消息");
      // }
    }, // 发送消息
    handleApiResponse(message) {
      // 处理 API 响应
      console.log("收到 API 响应:", message);
    },

    async handleOtherMessage(message) {
      // 处理其他类型的消息
      console.log("收到其他消息:", message);

      // 检查事件类型
      // 提取消息的基本信息
      const wechatid = message.data.robotId; // 微信ID
      const fromType = message.data.fromType; // 消息来源
      const fromWxId = message.data.fromWxId; // 发送者的微信ID
      const finalFromWxId = message.data.finalFromWxId; // 发送者的微信ID(群)
      // const messageType = message.data.messageType; // 消息类型
      const messageContent = message.data.message; // 消息内容
      const msgTime = message.data.timeStamp * 1000; // 消息时间
      // const date = new Date(message.data.timeStamp * 1000); // 转换为毫秒

      // 格式化日期
      // const formattedDate = (new Date(message.data.timeStamp * 1000)).toISOString(); // ISO 格式
      // console.log("ISO 格式:", formattedDate);

      // 或者使用其他格式
      const options = {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
        hour: "2-digit",
        minute: "2-digit",
        second: "2-digit",
        timeZoneName: "short",
      };
      const localDate = new Date(msgTime).toLocaleString("zh-CN", options); // 本地化格式
      // console.log("本地化格式:", localDate);

      console.log("接收微信ID:", wechatid);
      console.log("消息来源:", fromType);

      console.log("发送者微信ID:", fromWxId);

      const msgdatas = {
        wechatid: wechatid, //接收微信ID
        msgType: fromType, //消息类型
        cn: fromType === "group" ? "群" : "好友",
        fromWxId: fromWxId, //发送者微信ID(好友，群)
        finalFromWxId: fromType === "group" ? finalFromWxId : fromWxId,
        msgValue: messageContent, //消息内容
        time: localDate, //消息时间
        isViewed: false, //是否已读
      };
      let found = false;
      for (const item of this.wechatlist) {
        if (item.wxId === wechatid) {
          if (fromType === "group") {
            Object.entries(item.qluserlist).forEach(([key, value]) => {
              if (key === fromWxId) {
                value.msgdata = {
                  ...value.msgdata,
                  [msgTime]: msgdatas,
                };
                // console.log(value, 22221213);
                found = true;
              }
            });
          } else if (fromType === "private") {
            Object.entries(item.gruserlist).forEach(([key, value]) => {
              if (key === fromWxId) {
                value.msgdata = {
                  ...value.msgdata,
                  [msgTime]: msgdatas,
                };
                found = true;
              }
            });
          }
          // break;
          if (found) {
            break; // 如果找到匹配项，退出外层循环
          }
          // }
        }
      }
      console.log(this.wechatlist, 222);
      // await this.storeMessage(msgdatas);

      // switch (messageType) {
      //   case 1: // 文本消息
      //     console.log("文本-消息:", messageContent);
      //     break;
      //   case 3: // 图片消息
      //     console.log("图片-消息:", messageContent);
      //     break;
      //   case 34: // 语音消息
      //     console.log("语音-消息:", messageContent);
      //     break;
      //   case 42: // 名片消息
      //     console.log("名片-消息:", messageContent);
      //     break;
      //   case 43: // 视频消息
      //     console.log("视频-消息:", messageContent);
      //     break;
      //   case 10000: // 系统消息
      //     console.log("系统消息-内容:", messageContent);
      //     break;
      //   default:
      //     console.log("未知消息类型-类型:", messageType);
      //     break;
      // }

      this.$message({
        type: "success",
        message: `来自:(${message.data.fromWxId})的消息：${message.data.message}`,
      });
    },
    async storeMessage(obj) {
      console.log(obj, 111111);
    },
    async analyzeMessage(obj) {
      console.log(obj, 111111);
    },
    _chatobjdata(item) {
      console.log(item);
      this.chatobjdata = item;
      this.msgfrom = true;
      // console.log(this.chatobjdata);
    }, // 聊天对象数据
    async _buttonindex(index) {
      this.buttonindex = index;
      this.gruserlist = [];
      this.qluserlist = [];
      this.gruserlist = Object.values(
        this.wechatlist[this.buttonindex].gruserlist
      ).slice(0, 100);
      this.qluserlist = Object.values(
        this.wechatlist[this.buttonindex].qluserlist
      ).slice(0, 24);
      // if (!this.isSocketConnected) {
      //   this.$message({
      //     type: "warning",
      //     message: `本地服务器连接错误`,
      //   });

      //   return;
      // }
      // this.tiemMsg = this.$zcx.zcx_msgbox(
      //   this,
      //   true,
      //   { str: "获取好友列表...", tiem: 1000 },
      //   {}
      // );
      // this.buttonindex = index;
      // this.chatobjdata = "";
      // const date = {
      //   D: {
      //     type: "getFriendList",
      //     params: {
      //       robotId: this.wechatlist[index].wxId,
      //       cache: 1,
      //     },
      //   },
      //   H: {
      //     headers: {
      //       Authorization: this.starBottoken,
      //     },
      //   },
      // };
      // const ret = await this.wechatsendapi(date);

      // this.gruserlist = [];
      // // console.log(ret.data.data);
      // for (const item of ret.data.data) {
      //   this.gruserlist.push([
      //     item.wxId,
      //     item.remark || item.nickname,
      //     item.avatarUrl,
      //   ]);
      // }
      // // console.log(this.gruserlist, "微信好友列表");
      // this.$zcx.zcx_msgbox(this, false, {}, this.tiemMsg);

      // this.tiemMsg = this.$zcx.zcx_msgbox(
      //   this,
      //   true,
      //   { str: "获取群列表...", tiem: 1000 },
      //   {}
      // );
      // const date2 = {
      //   D: {
      //     type: "getGroupList",
      //     params: {
      //       robotId: this.wechatlist[index].wxId,
      //       cache: 1,
      //     },
      //   },
      //   H: {
      //     headers: {
      //       Authorization: this.starBottoken,
      //     },
      //   },
      // };
      // const ret2 = await this.wechatsendapi(date2);
      // // console.log(ret2.data);
      // this.qluserlist = [];
      // for (const item of ret2.data.data) {
      //   this.qluserlist.push([item.groupId, item.groupName, item.avatarUrl]);
      // }
      // // console.log(this.qluserlist, "微信群列表");
      // this.$zcx.zcx_msgbox(this, false, {}, this.tiemMsg);
      // return [ret, ret2];
    }, // 获取好友，群列表数据
    async _wechatuserListloading(wxid) {
      if (!this.isSocketConnected) {
        this.$message({
          type: "warning",
          message: `本地服务器连接错误`,
        });

        return;
      }
      this.tiemMsg = this.$zcx.zcx_msgbox(
        this,
        true,
        { str: "获取好友列表...", tiem: 1000 },
        {}
      );
      this.buttonindex = 0;
      // this.chatobjdata = "";
      const date = {
        D: {
          type: "getFriendList",
          params: {
            robotId: wxid,
            cache: 1,
          },
        },
        H: {
          headers: {
            Authorization: this.starBottoken,
          },
        },
      };
      const ret = await this.wechatsendapi(date);

      // this.gruserlist = [];
      // console.log(ret.data.data);
      // for (const item of ret.data.data) {
      //   this.gruserlist.push([
      //     item.wxId,
      //     item.remark || item.nickname,
      //     item.avatarUrl,
      //   ]);
      // }
      // console.log(this.gruserlist, "微信好友列表");
      this.$zcx.zcx_msgbox(this, false, {}, this.tiemMsg);

      this.tiemMsg = this.$zcx.zcx_msgbox(
        this,
        true,
        { str: "获取群列表...", tiem: 1000 },
        {}
      );
      const date2 = {
        D: {
          type: "getGroupList",
          params: {
            robotId: wxid,
            cache: 1,
          },
        },
        H: {
          headers: {
            Authorization: this.starBottoken,
          },
        },
      };
      const ret2 = await this.wechatsendapi(date2);
      // console.log(ret2.data);
      // this.qluserlist = [];
      // for (const item of ret2.data.data) {
      //   this.qluserlist.push([item.groupId, item.groupName, item.avatarUrl]);
      // }
      // console.log(this.qluserlist, "微信群列表");
      this.$zcx.zcx_msgbox(this, false, {}, this.tiemMsg);
      const grdata = ret.data.data.reduce((acc, item) => {
        acc[item.wxId] = { ...item, msgdata: {} }; // 使用 wxid 作为键
        return acc;
      }, {});
      const qldata = ret2.data.data.reduce((acc, item) => {
        acc[item.groupId] = { ...item, msgdata: {} }; // 使用 wxid 作为键
        return acc;
      }, {});
      // console.log(Object.keys(grdata).length, Object.keys(qldata).length, 2222);
      return [grdata, qldata];
    },
    _grqulistindex(e) {
      console.log(e, 111111);
      this.grqulistindex = e;
    }, // 好友，群列表 定位index
    _chatbuttonindex(index) {
      this.chatbuttonindex = index;
    }, // 聊天上方导航index
    async openWechat() {
      this.tiemMsg = this.$zcx.zcx_msgbox(
        this,
        true,
        { str: "获取微信登录状态...", tiem: 1000 },
        {}
      );
      for (const wxId of this.wxId) {
        const ret2 = await axios.post(
          "/api/processor",
          {
            type: "robotInfo",
            params: {
              robotId: wxId,
              cache: 1,
            },
          },
          {
            headers: {
              Authorization: `0480c6377bfd42368673bfc08ebc44f`,
            },
          }
        );
        if (ret2.data.code === 200) {
          const isExist = this.wechatlist.some((item) => item.wxId === wxId);
          if (!isExist) {
            this.wechatlist.push({
              wxId: wxId,
              wxname: ret2.data.data.nickname,
              image: ret2.data.data.avatarUrl,
            });
          }
        }
        // } else {
        //   this.wechatlist = this.wechatlist.filter(
        //     (item) => item.wxId !== wxId
        //   );
        // }
      }
      this.$zcx.zcx_msgbox(this, false, {}, this.tiemMsg);
    }, // 获取微信登录状态
  },
};
</script>

<style>
@import "./wechat.less";
</style>
