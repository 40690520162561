import { render, staticRenderFns } from "./mall.vue?vue&type=template&id=6e73ae94&scoped=true"
import script from "./mall.vue?vue&type=script&lang=js"
export * from "./mall.vue?vue&type=script&lang=js"
import style0 from "./mall.vue?vue&type=style&index=0&id=6e73ae94&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6e73ae94",
  null
  
)

export default component.exports