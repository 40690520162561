<template>
  <div>
    <div class="to2">
      <img
        src="../../assets/logo2.png"
        alt=""
        style="
          margin-top: -2vh;
          margin-left: 0;
          width: 10vw;
          height: auto;
          object-fit: contain;
        "
      />

      <div class="loadinglist" v-if="!mrft">
        <div class="loadinglistinput">
          <p style="font-size: 1vw">用户登录</p>
        </div>
        <div style="display: none">
          <input type="text" name="username" />
          <input type="password" name="password" />
        </div>
        <div class="loadinglistinput">
          <!-- <div class="loadinglistinputtext"><p>账号:</p></div> -->
          <input
            type="text"
            @keyup="debouncedHandleKeyup"
            v-model="fromlist.username"
            placeholder="请输入账号(username)"
            autocomplete="off"
            name="username-unique"
          />
        </div>
        <div
          class="loadinglistinput"
          style="height: 8%"
          v-if="fromlist.username !== '' && gslist === true && dx === false"
        >
          <!-- <div class="loadinglistinputtext"><p>账号:</p></div> -->
          <!-- <p>{{ gsvalue.item }}</p> -->
          <el-dropdown
            class="loadinglistinput"
            style="height: 70%"
            @command="handleCommand"
          >
            <input
              type="text"
              v-model="gsvalue.item"
              placeholder="请选择公司"
              autocomplete="off"
              readonly
              name="username-unique"
              style="
                cursor: pointer;
                height: 100%;
                border: 0.05vw solid #ffffff;
                color: #4d4d4d;
              "
            />
            <!-- <span class="el-dropdown-link">
            下拉菜单<i class="el-icon-arrow-down el-icon--right">readonly </i>
          </span> -->
            <el-dropdown-menu
              slot="dropdown"
              style="
                max-height: 30vh;
                max-width: 20vw;
                margin: 0.5vw;
                padding: 0.5vw;
                border: 0.1vw;
              "
              trigger="click"
            >
              <el-dropdown-item
                class="zcxel-dropdown-item"
                style="font-size: 0.7vw; line-height: 1vw; padding: 0 0.5vw"
                :command="[item, index]"
                v-for="(item, index) in filteredData.return.arr"
                :key="index"
                >{{ item }}</el-dropdown-item
              >
            </el-dropdown-menu>
          </el-dropdown>
        </div>
        <div class="loadinglistinput" v-if="dx === false">
          <!-- <div class="loadinglistinputtext"><p>密码:</p></div> -->
          <input
            type="password"
            v-model="fromlist.password"
            placeholder="请填输入密码(password)"
            autocomplete="off"
            name="password-unique"
          />
        </div>
        <div class="loadinglistinput" v-if="dx === true">
          <!-- <div class="loadinglistinputtext"><p style="font-size: 80%;">验证码:</p></div> -->
          <input
            class="dxrpinput"
            type="text"
            v-model="fromlist.uoun"
            placeholder="请输入验证码"
            autocomplete="off"
            name="verification-code-unique"
          />
          <button class="dxrp" @click="dxrphq" style="font-size: 0.5vw">
            {{ timetext }}
          </button>
        </div>
        <button class="loadingbut" @click="sendMessage">登录</button>
        <p style="color: #4d4d4d; font-size: 0.5vw" @click="ce" class="p-click">
          {{ dxtext }}
        </p>
        <!-- <button @click="del" style="background-color: #ffffff;width: 200px;height: 50px">跨域</button> -->
      </div>
      <div class="loadinglist2" v-if="mrft">
        <p>选择公司</p>
        <ul
          class="infinite-list"
          v-infinite-scroll="load"
          style="overflow: auto"
        >
          <li
            v-for="(item, index) in userdata"
            class="infinite-list-item"
            :key="index"
            @click="ce2(index)"
          >
            {{ item }}
          </li>
        </ul>
        <!-- <div v-for="item,index in filteredData" :key="index" >
            <p @click="ce2(item.key)">{{ item.value['gsname']}}</p>
            <p @click="ce2(item.key)">{{ item.value['gsname']}}</p><p @click="ce2(item.key)">{{ item.value['gsname']}}</p>
          </div> -->
      </div>
    </div>
    <!-- <div>登录框</div> -->
    <div
      style="
        position: absolute;
        bottom: 0;
        text-align: center;
        padding: 10px 0;
        font-size: 1vh;
        color: #4d4d4d;
      "
    >
      <a
        href="https://beian.miit.gov.cn/"
        style="
          color: #4d4d4d;
          text-decoration: none;
          margin-left: auto;
          /* margin-right: auto; */
        "
        >链动电子科技 | 蜀ICP备2024045578号</a
      >
    </div>
  </div>
</template>

<script>
import { debounce } from "lodash";
export default {
  data() {
    return {
      dx: false,
      dxtext: "验证码登录",
      timetext: "获取验证码",
      countertime: [0, 60],
      fromlist: {
        username: "",
        password: "",
        uoun: "",
      },
      count: 0,
      userdata: {},
      mrft: false,
      debouncedHandleKeyup: null,
      gslist: false,
      // gsvalue:['123',''],
      gsvalue: {
        item: "",
        adminid: "",
      },
    };
  },
  computed: {
    filteredData() {
      // 过滤以 'dl' 开头的数据项
      return this.$zcx.zcx_cacheItem(
        2,
        `zcx_usergs-${this.fromlist.username}`
      ) !== null
        ? this.$zcx.zcx_cacheItem(2, `zcx_usergs-${this.fromlist.username}`)
        : { return: { arr: {} } };
    },
    gsvalueda() {
      return this.gsvalue;
    },
    formtf() {
      const phoneRegex = /^1[3-9]\d{9}$/;
      if (this.fromlist.username === "") {
        this.$message({
          type: "warning",
          message: `账户不能为空`,
        });
        return;
      }
      if (!phoneRegex.test(this.fromlist.username)) {
        this.$message({
          type: "warning",
          message: `账户错误`,
        });
        return;
      }
      if (this.gsvalue.item === "") {
        this.$message({
          type: "warning",
          message: `需要选择公司`,
        });
        return;
      }
      if (this.fromlist.password === "") {
        this.$message({
          type: "warning",
          message: `密码不能为空`,
        });
        return;
      }
      return true;
    },
  },
  created() {
    this.debouncedHandleKeyup = debounce(this.handleKeyup, 500);
  },
  methods: {
    del() {
      // 测试跨域-------------------------------------------------------------------------------------------------------
      // console.log(this.$zcx.zcx_usermr(1))
      this.$get("https://agent.liandongapp.cn:2079/")
        .then((response) => {
          console.log("EK:", response); // 打印获取到的值
        })
        .catch((error) => {
          console.error("Error fetching EK:", error); // 打印错误信息
        });

      // console.log(await this.$get('https://web.liandongapp.cn/zcxce'))
    }, // 测试跨域--------------------------------------------------------------------------------------------------------
    handleCommand(command) {
      this.gsvalue.item = command[0];
      this.gsvalue.adminid = command[1];
    },
    handleKeyup() {
      if (this.dx) {
        return;
      }
      this.gsvalue = {
        item: "",
        adminid: "",
      };
      const phoneRegex = /^1[3-9]\d{9}$/;
      // console.log(this.filteredData.return.arr)
      if (phoneRegex.test(this.fromlist.username)) {
        if (Object.keys(this.filteredData.return.arr).length !== 0) {
          // console.log(this.filteredData.date,Date.now(),Date.now()-this.filteredData.date)
          if (Date.now() - this.filteredData.date > 7 * 24 * 60 * 60000) {
            this.$message({
              type: "warning",
              message: `验证过期，需要从新登录`,
            });
            this.$zcx.zcx_cacheItem(
              3,
              `zcx_usergs-${this.fromlist.username}`,
              {}
            );
            this.gslist = false;
            this.ce();
          } else {
            this.$message({
              type: "warning",
              message: `请选择公司`,
            });

            this.gslist = true;
          }
        } else {
          this.$message({
            type: "warning",
            message: `需要手机验证`,
          });
          this.gslist = false;
          this.dx = true;
          this.dxtext = "密码登录";
        }

        //  console.log(this.filteredData.date)
      } else {
        this.$message({
          type: "warning",
          message: `请输入正确的手机号`,
        });
        this.gslist = false;
      }
    },
    load() {
      this.count += 2;
    },
    dl() {
      // console.log("1111")
      location.has = "#/index";
    },
    async sendMessage() {
      if (this.dx) {
        const tiemMsg = this.$zcx.zcx_msgbox(
          this,
          true,
          { str: "登录中...", tiem: 20000 },
          {}
        );
        try {
          const zcxdata = this.$zcxhttp.zcxhttpAdddata(
            "zcx_message",
            {
              phone: this.fromlist.username,
              password: this.fromlist.password,
              uoun: this.fromlist.uoun,
            },
            {}
          );
          const config = {
            headers: {
              zcxy: "web",
              "Content-Type": "application/json",
              Authorization: "Bearer your_token_here",
            },
            timeout: 5000, // 5秒超时
            responseType: "json", // 默认是 'json'
            withCredentials: false,
          };
          // console.log(111)
          let ret;
          try {
            // console.log(zcxdata, config);
            ret = (await this.$axios.post(`${zcxdata.url}`, zcxdata, config))
              .data;
            // console.log("Response:", ret);
          } catch (error) {
            console.error("Request failed:", error);
          }

          // let ret = (await this.$axios.post(`${zcxdata.url}`,zcxdata,config)).data
          // console.log(ret)
          if (ret.return.loginType) {
            console.log("Response:", ret);
            ret.date = Date.now();
            this.$zcx.zcx_cacheItem(
              1,
              `zcx_usergs-${this.fromlist.username}`,
              ret
            );
            this.$zcx.zcx_msgbox(this, false, {}, tiemMsg);
            this.$zcx.zcx_msgbox(
              this,
              true,
              { str: "获取数据成功，需要输入登录密码", tiem: 1500 },
              {}
            );
            this.fromlist.uoun = "";
            this.gslist = true;
            this.ce();
          } else {
            this.$zcx.zcx_msgbox(this, false, {}, tiemMsg);
            this.$zcx.zcx_msgbox(
              this,
              true,
              { str: "获取信息失败", tiem: 1000 },
              {}
            );
          }
        } catch (error) {
          console.error("zcxError:", error);
        } finally {
          this.$zcx.zcx_msgbox(this, false, {}, tiemMsg);
        }
      } else {
        if (this.formtf) {
          const tiemMsg = this.$zcx.zcx_msgbox(
            this,
            true,
            { str: "登录中...", tiem: 20000 },
            {}
          );
          const reqlist = this.$zcx.zcx_cacheItem(
            2,
            `zcx_usergs-${this.fromlist.username}`
          );
          const zcxdata = this.$zcxhttp.zcxhttpAdddata(
            "zcx_logintf",
            {
              ...reqlist.return.cev,
              phone: this.fromlist.username,
              password: this.fromlist.password,
              adminid: this.gsvalue.adminid,
            },
            {}
          );
          const config = {
            headers: {
              zcxy: "web",
              "Content-Type": "application/json",
              Authorization: "Bearer your_token_here",
            },
            timeout: 5000, // 5秒超时
            responseType: "json", // 默认是 'json'
            withCredentials: false,
          };
          // let ret = (await this.$axios.post(`${zcxdata.url}`,zcxdata,config)).data
          let ret;
          try {
            ret = (await this.$axios.post(`${zcxdata.url}`, zcxdata, config))
              .data;
            // console.log("Response:", ret);
          } catch (error) {
            console.error("Request failed:", error);
          }
          if (ret.eree === null) {
            this.$zcx.zcx_msgbox(this, false, {}, tiemMsg);
            // ret.ret = {
            //   ...ret.ret,
            //   phone: this.fromlist.username,
            // };
            console.log(ret, 111112);
            this.$zcx.zcx_usermr(2, ret);
            this.$emit("message-from-child", true);
            this.$zcx.zcx_msgbox(
              this,
              true,
              { str: "登录成功", tiem: 1000 },
              {}
            );
            // console.log(this.$zcx.zcx_usermr(1));
          } else {
            this.$zcx.zcx_msgbox(this, false, {}, tiemMsg);
            this.$message({
              type: "warning",
              message: `${ret.eree}`,
            });
          }
        }
      }
    },
    ce() {
      this.dx = this.dx === false ? true : false;
      this.dxtext = this.dx === false ? "验证码登录" : "密码登录";
    },
    async ce2(e) {
      console.log(e);
      // localStorage.removeItem('zcx_username');
      // this.userdata.data.mr={...this.userdata.data[e]}
      // localStorage.setItem('zcx_username',  JSON.stringify(this.userdata.data));
      // localStorage.setItem('zcx_loading',true)
      // this.$emit('message-from-child', true);
      // // console.log(this.userdata)
      // // localStorage.setItem('_userdata', JSON.stringify(this.userdata.data));
      // // const zcxdata=this.$zcxhttp.zcxhttpAdddata('zcx_insert',{zcx_db:'zcx_userloading',phone:this.fromlist.username,data:this.userdata.data},{})
      // // const config = {
      // //     headers: {
      // //       "zcxy": "web",
      // //       'Content-Type': 'application/json',
      // //       'Authorization': 'Bearer your_token_here'
      // //     },
      // //     timeout: 5000, // 5秒超时
      // //     responseType: 'json', // 默认是 'json'
      // // };
      // // let ret = (await this.$axios.post(`${zcxdata.url}`,zcxdata,config)).data
    },
    async dxrphq() {
      if (this.countertime[0] !== 0) {
        this.$message({
          type: "info",
          message: `${this.timetext}`,
        });
        return;
      }
      if (this.countertime[0] === 0) {
        this.countertime[0] = 1;
        let zcxdata = this.$zcxhttp.zcxhttpAdddata(
          "zcx_message",
          {
            phone: this.fromlist.username,
            password: this.fromlist.password,
            uoun: this.fromlist.uoun,
          },
          {}
        );
        const config = {
          headers: {
            zcxy: "web",
            "Content-Type": "application/json",
            Authorization: "Bearer your_token_here",
          },
          timeout: 5000, // 5秒超时
          responseType: "json", // 默认是 'json'
          withCredentials: false,
        };
        const ret = (await this.$axios.post(`${zcxdata.url}`, zcxdata, config))
          .data;

        this.$message({
          type: "info",
          message: ret.return === true ? `发送成功` : "电话号码错误",
        });
        this.countertime[2] = setInterval(() => {
          this.countertime[0]++;
          this.timetext =
            this.countertime[1] - this.countertime[0] + "秒后，重新获取";
          if (this.countertime[0] === this.countertime[1]) {
            this.countertime[0] = 0;
            this.timetext = "获取验证码";
            clearInterval(this.countertime[2]);
          }
        }, 1000);
      }
    },
  },
};
</script>

<style lang="less" scoped>
@import "./k.less";
</style>
